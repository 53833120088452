export function toggleLayoutOverflowHidden(overflowHide: boolean) {
  const overflowHiddenClassName = "layout-overflow-hidden";
  overflowHide
    ? document.body.classList.add(overflowHiddenClassName)
    : document.body.classList.remove(overflowHiddenClassName);
}

export function smoothJumpToSectionOnAnchorLink() {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
}
