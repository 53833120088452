import { FormControl, MenuItem, Select } from "@mui/material";
import React, { FC } from "react";

import { MenuItem as AppMenuItem } from "../MenuItem/MenuItem";
import { MenuNavProps } from "../MenuNav/MenuNav";
import { TMenuItem } from "../models";
import { ScreenType } from "../../models";

export interface MobileMenuNavProps extends MenuNavProps {
  items: TMenuItem[];
  currentItem: TMenuItem;
  onItemClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MobileMenuNav: FC<MenuNavProps> = (props: MenuNavProps) => {
  const componentName = "menu-nav";
  const { items, currentItem, onItemClick } = props;

  const [age, setAge] = React.useState(0);

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };

  return (
    <div className={`${componentName}`}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select id="demo-simple-select-standard" value={age} onChange={handleChange}>
          {items.map((item, index) => {
            return (
              <MenuItem key={index} value={index}>
                <AppMenuItem
                  key={`${componentName}__item-${index}`}
                  icon={item.icon}
                  text={item.text}
                  active={item.screenType === currentItem.screenType}
                  onClick={onItemClick}
                  screenType={item.screenType}
                  menuType={ScreenType.MOBILE}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
