import { FAQCategory, IFAQItem } from "./FAQModels";
import { FAQItemProps } from "../FAQItem/FAQItem";

const FAQ_PHOTO: Array<IFAQItem> = [
  {
    category: FAQCategory.PHOTO,
    question: "PHOTO Question 1?",
    answer: "PHOTO Answer 1.",
  },
  {
    category: FAQCategory.PHOTO,
    question: "PHOTO Question 2?",
    answer: "PHOTO Answer 2.",
  },
  {
    category: FAQCategory.PHOTO,
    question: "PHOTO Question 3?",
    answer: "PHOTO Answer 3.",
  },
];

const FAQ_VIDEO: Array<IFAQItem> = [
  {
    category: FAQCategory.VIDEO,
    question: "VIDEO Question 1?",
    answer: "VIDEO Answer 1.",
  },
  {
    category: FAQCategory.VIDEO,
    question: "VIDEO Question 2?",
    answer: "VIDEO Question 2.",
  },
  {
    category: FAQCategory.VIDEO,
    question: "VIDEO Question 3?",
    answer: "VIDEO Answer 3.",
  },
];

const FAQ_WEB: Array<IFAQItem> = [
  {
    category: FAQCategory.WEB,
    question: "WEB Question 1?",
    answer: "WEB Answer 1.",
  },
  {
    category: FAQCategory.WEB,
    question: "WEB Question 2?",
    answer: "WEB Answer 2.",
  },
  {
    category: FAQCategory.WEB,
    question: "WEB Question 3?",
    answer: "WEB Answer 3.",
  },
];

export const FAQ_ITEMS = [...FAQ_PHOTO, ...FAQ_VIDEO, ...FAQ_WEB];
