export enum FAQCategory {
  PHOTO = "photo",
  VIDEO = "video",
  WEB = "web",
}

export interface IFAQItem {
  category: FAQCategory;
  question: string;
  answer: string;
}
