import React from "react";
import { Section } from "../Section/Section";
import "./SectionService.scss";
import img1 from "../../assets/images/services/4image.jpg";
import img2 from "../../assets/images/services/2image.jpg";
import img3 from "../../assets/images/services/5image.jpg";
import img4 from "../../assets/images/services/6image.jpg";
import img5 from "../../assets/images/services/3image.jpg";
import img6 from "../../assets/images/services/1image.jpg";

export const SectionService: React.FC = () => {
  return (
    <Section title="Naše usluge">
      <div className="service">
        <div className="service__container">
          <img className="service__container__img" src={img1} />
          <div className="service__container__text">
            <p>U trenutku kada Vas dvoje postajete jedno i ulazite u nešto za sve Vas novo.<br/>Želimo da Vam taj trenutak ostane trajno zapisan i da se nikad ne zaboravi.</p>
          </div>
        </div>
        <div className="service__container service__container service__container service__container--even">
          <img className="service__container__img" src={img2} />
          <div className="service__container__text">
            <p>
              Da se toga dana Vaše ruke spoje i nikada ne razdvoje.<br/>
              Kasnije kada najvažniji dan u Vaš životu prođe, pogledom na fotografije i video pobudi u Vama 
              bar djelić emocija koje ste prolazili toga dana.
            </p>
          </div>
        </div>
        <div className="service__container">
          <img className="service__container__img" src={img3} />
          <div className="service__container__text">
            <p>
            Svoju posljednju djevojačku večer bilo bi uredu da se proslavi kako treba.<br/>
            Ali zato možda je bolje prije zabilježiti fotkama jer nikad se ne zna u kojem smjeru može otići.<br/>
            Sigurno se svi prisjećamo scena iz filma Mamurluk.
            </p>
          </div>
        </div>
        <div className="service__container service__container service__container service__container--even">
          <img className="service__container__img" src={img4} />
          <div className="service__container__text">
            <p>
              I od ovog trenutka dijete moje drago postao si dijete Božje.<br/>
              Najvjerojatnije da se ovog trenutka nećeš ni sjećati, 
              a tako ni naših emocija koje smo imali kada smo te doveli Gospodinu.<br/>
              Ali zato jedva čekamo da malo odrasteš pa da zajedno gledamo fotografije tvoga krštenja i prođemo kroz isto što smo prolazili ovoga trenutka.
            </p>
          </div>
        </div>
        <div className="service__container">
          <img className="service__container__img" src={img5} />
          <div className="service__container__text">
            <p>
              Dani, tjedni, mjeseci tako brzo prolaze, a oni još brže rastu.<br/>
              Sve više i više svjećica se gasi na njihovim tortama.<br/>
              Zato im treba zabilježiti svaku zamišljenu želju i ugašenu svjećicu.
            </p>
          </div>
        </div>
        <div className="service__container service__container service__container service__container--even">
          <img className="service__container__img" src={img6} />
          <div className="service__container__text">
            <p>
              Svi se rado prisjećamo svoji školskih dana. Usudili bi se reći i najboljih dana, zato i zauzmu neka lijepa mjesta u našim srcima.<br/>
              A još je ljepše ponovno se okupiti i svih lijepih sjećanja se prisjetiti.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};
