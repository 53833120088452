import React, { useState } from "react";
import { Grid } from "@mui/material";
import {
  Section,
  SectionScreenAboutUs,
} from "../../..";
import "./SectionAboutUs.scss";

const componentName = "about-us-section";

export const SectionAboutUs: React.FC = () => {
  return (
    <Section title="O meni" id={componentName} className={componentName}>
      <div className={`${componentName}__container`}>
        <div className={`${componentName}__container__content`}>
          <div className={`${componentName}__container__content__dinamic`}>
            <SectionScreenAboutUs />
          </div>
          <div className={`${componentName}__container__content__static`}></div>
        </div>
      </div>
    </Section>
  );
};
