import React from "react";

import "./Tooltip.scss";

export enum TooltipPosition {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
}

interface TooltipProps {
  children: JSX.Element | Array<JSX.Element>;
  text: string;
  position: TooltipPosition;
}

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const { children, text, position } = props;

  return (
    <div className={`tooltip tooltip-${position}`} data-tooltip={text}>
      {children}
    </div>
  );
};

export default Tooltip;
