import React from "react";

import "./SlideNav.scss";

export interface SlideNavItemProps {
  isCurrent: boolean;
  onClick: () => void;
}

export const SlideNavItem: React.FC<SlideNavItemProps> = (
  props: SlideNavItemProps
) => {
  const { isCurrent, onClick } = props;
  return (
    <li className="slide-nav__item">
      <button type="button" aria-current={isCurrent} onClick={onClick}>
        <span />
      </button>
    </li>
  );
};

export interface SlideNavProps {}

export const SlideNav: React.FC<SlideNavProps> = (props: SlideNavProps) => {
  return <ul className="slide-nav" {...props} />;
};
