import React from "react";

import { FAIcon } from "../../common";
import "./Rights.scss";

export const Rights: React.FC = () => {
  const year = new Date().getFullYear();
  const companyName = "Mb Creative Media";
  const rightsText = "sva prava pridržana.";

  const rightsString = ` ${year} ${companyName} ${rightsText}`;

  return (
    <div className="rights">
      <FAIcon icon={["far", "copyright"]} />
      {rightsString}
    </div>
  );
};
