import { faHome, faInfoCircle, faEnvelope, faHandHolding } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTiktok, faYoutube, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

import { NavLinkProps } from "./NavLink/NavLink";
import { LabelProps } from "../../../common";
import { LabelType } from "../../../common/Label/Label";

type NavBarConfig = {
  links: Array<NavLinkProps>;
  contacts: Array<LabelProps>;
};

export const NAVBAR_CONFIG: NavBarConfig = {
  links: [
    {
      key: "home-link",
      toPath: "/",
      icon: faHome,
      text: "Naslovna",
    },
    {
      key: "our-services'",
      toPath: "/usluge",
      icon: faHandHolding,
      text: "Usluge",
    },
    {
      key: "about-us-link",
      toPath: "/o-meni",
      icon: faInfoCircle,
      text: "O Nama",
    },
    {
      key: "kontakt-link",
      toPath: "/kontakt",
      icon: faEnvelope,
      text: "Kontakt",
    },
  ],
  contacts: [
    {
      key: "youtube-contact",
      icon: faYoutube,
      labelType: LabelType.STANDARD,
      toPath: "https://www.youtube.com/channel/UCdKS5FUX0eU-vVdEaNh-llg",
    },
    {
      key: "instagram-contact",
      icon: faInstagram,
      labelType: LabelType.STANDARD,
      toPath: "https://www.instagram.com/mb.creativemedia/",
    },
    {
      key: "facebook-contact",
      icon: faFacebookSquare,
      labelType: LabelType.STANDARD,
      toPath: "https://web.facebook.com/MbCreativeMedia1",
    },
    {
      key: "tiktok-contact",
      icon: faTiktok,
      labelType: LabelType.STANDARD,
      toPath: "https://www.tiktok.com/@mbcreativemedia",
    },
  ],
};
