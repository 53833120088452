import React, { useEffect, useState } from "react";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import "./ScrollToTop.scss";

interface ScrollToTopProps {}

const ScrollToTop: React.FC<ScrollToTopProps> = (props: ScrollToTopProps) => {
  const [isVisable, setIsVisable] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisable(true);
    } else {
      setIsVisable(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisable ? (
        <KeyboardArrowUpRoundedIcon className="scroll-to-top" onClick={scrollToTop} />
      ) : null}
    </>
  );
};

export default ScrollToTop;
