export enum ActivityState {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum BackgroundType {
  DARK = "dark",
  LIGHT = "LIGHT",
}

export enum ScreenType {
  MOBILE = "mobile",
  DESKTOP = "desktop",
}
