import React from "react";

interface LabelProps {
  text: string;
}

const Button: React.FC<LabelProps> = (props: LabelProps) => {
  const { text } = props;

  return (
    <div className="label">
      <span>{text}</span>
    </div>
  );
};

export default Button;
