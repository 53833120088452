import React from "react";

import { Brand, LabelProps } from "../../../../common";
import { NAVBAR_CONFIG } from "../NavBarConfig";
import { NavLinkProps } from "../NavLink/NavLink";
import "./Nav.scss";

interface NavProps {
  onRenderLinks: (links: Array<NavLinkProps>) => JSX.Element[] | null;
  onRenderContacts: (contacts: Array<LabelProps>) => JSX.Element[] | null;
}

const Nav: React.FC<NavProps> = (props: NavProps) => {
  const { onRenderLinks, onRenderContacts } = props;
  const { links, contacts } = NAVBAR_CONFIG;

  return (
    <div className="nav">
      <Brand />
      <nav className={`nav__links`}>{onRenderLinks(links)}</nav>
      <div className={`nav__contact`}>
        <div className="nav__contact__links">{onRenderContacts(contacts)}</div>
      </div>
    </div>
  );
};

export default Nav;
