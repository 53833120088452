import React, { FC, ReactElement } from "react";
import "./MenuScreen.scss";

export interface MenuScreenProps {
  component: ReactElement<any>;
}

export const MenuScreen: FC<MenuScreenProps> = (props: MenuScreenProps) => {
  const componentName = "menu-screen";
  const { component } = props;

  return <div className={`${componentName}__container`}>{component}</div>;
};
