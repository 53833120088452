import React from "react";
import { GatsbyBrowser } from "gatsby";
import { Provider as ReduxStoreProvider } from "react-redux";
import { store } from "../../modules/redux";

const RootElementWrapper: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <ReduxStoreProvider store={store}>{element}</ReduxStoreProvider>;
};

export default RootElementWrapper;
