import React from "react";
import { Carousel, Section } from "../../../../common";

import { MainCarouselConfig as CarouselConfig } from "./MainCarouselConfig";
import "./SectionMainCarousel.scss";

export const SectionMainCarousel: React.FC = () => {
  const componentName = "main-carousel-section";

  return (
    <Section id={componentName} className={componentName}>
      <Carousel slides={CarouselConfig.sliedes} slideDuration={CarouselConfig.slideDuration} />
    </Section>
  );
};
