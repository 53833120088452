import React from "react";
import { Link } from "gatsby";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

import { Label, ActivityState } from "../../../../common";
import { useAppSelector } from "../../../../modules/redux";
import "./NavLink.scss";

export interface NavLinkProps {
  key?: string;
  toPath: string;
  icon?: IconDefinition;
  text?: string;
  className?: string;
}

export const NavLink: React.FC<NavLinkProps> = (props: NavLinkProps) => {
  const { toPath, icon, text, className } = props;
  const { currentUri } = useAppSelector((state) => state.appContext);
  const activityState = toPath === currentUri ? ActivityState.ACTIVE : ActivityState.INACTIVE;

  return (
    <Link
      to={toPath}
      className={`nav-link nav-link--${activityState} ${className ? className : ""}`}
    >
      <Label icon={icon} text={text} />
    </Link>
  );
};
