exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dogadjaji-djakovacki-vezovi-tsx": () => import("./../../../src/pages/dogadjaji/djakovacki-vezovi.tsx" /* webpackChunkName: "component---src-pages-dogadjaji-djakovacki-vezovi-tsx" */),
  "component---src-pages-dogadjaji-djevojacka-tihana-tsx": () => import("./../../../src/pages/dogadjaji/djevojacka-tihana.tsx" /* webpackChunkName: "component---src-pages-dogadjaji-djevojacka-tihana-tsx" */),
  "component---src-pages-dogadjaji-krstenje-diego-tsx": () => import("./../../../src/pages/dogadjaji/krstenje-diego.tsx" /* webpackChunkName: "component---src-pages-dogadjaji-krstenje-diego-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-meni-tsx": () => import("./../../../src/pages/o-meni.tsx" /* webpackChunkName: "component---src-pages-o-meni-tsx" */),
  "component---src-pages-suradnje-leilas-garden-tsx": () => import("./../../../src/pages/suradnje/leilas-garden.tsx" /* webpackChunkName: "component---src-pages-suradnje-leilas-garden-tsx" */),
  "component---src-pages-usluge-tsx": () => import("./../../../src/pages/usluge.tsx" /* webpackChunkName: "component---src-pages-usluge-tsx" */),
  "component---src-pages-vjencanja-ana-i-lorenzo-tsx": () => import("./../../../src/pages/vjencanja/ana-i-lorenzo.tsx" /* webpackChunkName: "component---src-pages-vjencanja-ana-i-lorenzo-tsx" */)
}

