import { useState, useEffect } from "react";
import { DeviceType, MediaQueryBrakepoint, ScreenDimensions } from "./Models";

function getDeviceType(windowWidth: number) {
  switch (true) {
    case MediaQueryBrakepoint.EXTRA_SMALL <= windowWidth &&
      windowWidth < MediaQueryBrakepoint.SMALL:
      return DeviceType.EXTRA_SMALL;
    case MediaQueryBrakepoint.SMALL <= windowWidth && windowWidth < MediaQueryBrakepoint.MEDIUM:
      return DeviceType.SMALL;
    case MediaQueryBrakepoint.MEDIUM <= windowWidth && windowWidth < MediaQueryBrakepoint.LARGE:
      return DeviceType.MEDIUM;
    case MediaQueryBrakepoint.LARGE <= windowWidth && windowWidth < MediaQueryBrakepoint.LAPTOP:
      return DeviceType.LARGE;
    case windowWidth <= MediaQueryBrakepoint.EXTRA_LARGE &&
      windowWidth < MediaQueryBrakepoint.EXTRA_LARGE:
      return DeviceType.LAPTOP;
    case windowWidth >= MediaQueryBrakepoint.EXTRA_LARGE:
      return DeviceType.EXTRA_LARGE;
    default:
      return DeviceType.SMALL;
  }
}

function getScreenDimensions() {
  const screenDimensions: ScreenDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
    deviceType: getDeviceType(window.innerWidth),
  };

  return screenDimensions;
}

function useScreenDimensions() {
  const isWindowDefined = typeof window !== "undefined";
  const ScreenDimensions = isWindowDefined ? getScreenDimensions() : null;
  const [screenDimensions, setScreenDimensions] = useState(ScreenDimensions);

  useEffect(() => {
    function handleResize() {
      setScreenDimensions(getScreenDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenDimensions;
}

export { useScreenDimensions };
