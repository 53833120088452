import React from "react";
import { MainMenuScreen, MenuProps } from "../../../../common";
import {
  MainMenuCategories,
  MainMenuCategoriesProps,
} from "./MainMenuCategories/MainMenuCategories";
import bachelorettePartyTihanaHeadingImg from "../../../../assets/images/events/bachelorette_party/tihana/main.jpg";
import weddingAnnaAndLorenzo from "../../../../assets/images/weddings/wedding_ana_and_lorenzo.jpg";
import diegoBaptism from "../../../../assets/images/events/baptism/main.jpg";
import leilasGarden from "../../../../assets/images/partnership/leilas/leilas_garden_0.jpg";
import djakovo from "../../../../assets/images/events/other/djakovacki-vezovi/main.jpg";
import {
  faListCheck,
  faCalendarDays,
  faRing,
  faHandshake,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";

const MAIN_MENU_OPTIONS_CONFIG: MainMenuCategoriesProps = {
  categories: [
    {
      title: "Događaji",
      items: [
        {
          text: "Tihanina djevojačka",
          link: "/dogadjaji/djevojacka-tihana",
          imgSrc: bachelorettePartyTihanaHeadingImg,
        },
        { 
          text: "Diego krštenje", 
          link: "/dogadjaji/krstenje-diego", 
          imgSrc: diegoBaptism 
        },
        { 
          text: "Đakovački vezovi", 
          link: "/dogadjaji/djakovacki-vezovi", 
          imgSrc: djakovo
        },
      ],
    },
    {
      title: "Vjenčanja",
      items: [
        { 
          text: "Ana i Lorenco",
          link: "/vjencanja/ana-i-lorenzo",
          imgSrc: weddingAnnaAndLorenzo 
        },
      ],
    },
    {
      title: "Suradnje",
      items: [
        { 
          text: "Leila's Garden", 
          link: "/suradnje/leilas-garden", 
          imgSrc: leilasGarden 
        }
      ],
    },
  ],
};

export const MAIN_MENU_CONFIG: MenuProps = {
  items: [
    {
      text: "Projekti",
      icon: faListCheck,
      screenType: MainMenuScreen.PROJECTS,
      component: <MainMenuCategories categories={MAIN_MENU_OPTIONS_CONFIG.categories} />,
    },
    {
      text: "Događaji",
      icon: faCalendarDays,
      screenType: MainMenuScreen.EVENTS,
      component: (
        <MainMenuCategories
          categories={MAIN_MENU_OPTIONS_CONFIG.categories.filter(
            (category) => category.title === "Događaji"
          )}
        />
      ),
    },
    {
      text: "Vjenčanja",
      icon: faRing,
      screenType: MainMenuScreen.WEDDINGS,
      component: (
        <MainMenuCategories
          categories={MAIN_MENU_OPTIONS_CONFIG.categories.filter(
            (category) => category.title === "Vjenčanja"
          )}
        />
      ),
    },
    {
      text: "Suradnje",
      icon: faHandshake,
      screenType: MainMenuScreen.COOPERATION,
      component: (
        <MainMenuCategories
          categories={MAIN_MENU_OPTIONS_CONFIG.categories.filter(
            (category) => category.title === "Suradnje"
          )}
        />
      ),
    },
    // {
    //   text: "Putovanja",
    //   icon: faPlaneDeparture,
    //   screenType: MainMenuScreen.TRAVELS,
    //   component: (
    //     <MainMenuCategories
    //       categories={MAIN_MENU_OPTIONS_CONFIG.categories.filter(
    //         (category) => category.title === "Putovanja"
    //       )}
    //     />
    //   ),
    // },
  ],
};
