import React from "react";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "../../common";
import "./Label.scss";
import { Link } from "gatsby";

export enum LabelType {
  MAIL_TO = "mail-to",
  COPYABLE = "copyable",
  STANDARD = "standard",
}

export interface LabelProps extends Partial<FontAwesomeIconProps> {
  key?: string;
  text?: string;
  icon?: IconDefinition;
  labelType?: LabelType;
  toPath?: string;
}

const Label: React.FC<LabelProps> = (props: LabelProps) => {
  const { text, icon, size, toPath } = props;

  return (
    <>
      {toPath ? (
        <a className="label" href={toPath} target="_blank">
          {icon != null ? <FAIcon icon={icon} size={size} className="label__icon" /> : null}
          <span className="label__text">{text}</span>
        </a>
      ) : (
        <div className="label">
          {icon != null ? <FAIcon icon={icon} size={size} className="label__icon" /> : null}
          <span className="label__text">{text}</span>
        </div>
      )}
    </>
  );
};

export default Label;
