import React, { useEffect, useCallback, useState, FC } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CardProduct } from "../../components";
import { BackgroundType } from "../../common";
import { defaultProductCardListConfig } from "./CardProductListConfig";
import { Control } from "../../common/Carousel/Controls";
import "./CardCarousel.scss";

const productCardList = defaultProductCardListConfig;
const className = "card-carousel";

interface CardCarouselIndexesProps {
  previousIndex: number;
  currentIndex: number;
  nextIndex: number;
}

const setCardCarouselItemStatus = (indexes: CardCarouselIndexesProps, cardIndex: number) => {
  if (indexes.currentIndex === cardIndex) {
    return "active";
  } else {
    return "inactive";
  }
};

interface CardCarouselProps {
  className?: string;
  autoRotate?: boolean;
  interval?: number;
  onCardChange?: (indexes: CardCarouselIndexesProps) => void;
}

export const CardCarousel: FC<CardCarouselProps> = (props: CardCarouselProps) => {
  const { autoRotate = false, interval = 2000, onCardChange } = props;
  const cardItems = productCardList.items;
  const [indexes, setIndexes] = useState<CardCarouselIndexesProps>({
    previousIndex: cardItems.length - 1,
    currentIndex: 0,
    nextIndex: 1,
  });

  const handleCardTransition = useCallback(() => {
    if (indexes.currentIndex >= cardItems.length - 1) {
      setIndexes({
        previousIndex: cardItems.length - 1,
        currentIndex: 0,
        nextIndex: 1,
      });
    } else {
      setIndexes((prevState) => ({
        previousIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex + 1,
        nextIndex: prevState.currentIndex + 2 === cardItems.length ? 0 : prevState.currentIndex + 2,
      }));
    }
  }, [indexes.currentIndex]);

  const handleLeftButton = useCallback(() => {
    if (indexes.currentIndex <= 0) {
      setIndexes({
        previousIndex: cardItems.length - 2,
        currentIndex: cardItems.length - 1,
        nextIndex: 0,
      });
    } else {
      setIndexes((prevState) => ({
        nextIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex - 1,
        previousIndex:
          prevState.currentIndex - 1 <= 0 ? cardItems.length - 1 : prevState.currentIndex - 2,
      }));
    }
  }, [indexes.currentIndex]);

  useEffect(() => {
    onCardChange && onCardChange(indexes);
    const transitionInterval = setInterval(() => {
      autoRotate && handleCardTransition();
    }, interval);

    return () => clearInterval(transitionInterval);
  }, [handleCardTransition, indexes, autoRotate]);

  return (
    <div className={`${className}`}>
      <div className={`${className}__header`}></div>
      <div className={`${className}__container`}>
        <Control
          aria-label="Previous Slide"
          icon={faAngleLeft}
          className="control__prev"
          onClick={handleLeftButton}
          forBackground={BackgroundType.LIGHT}
        />
        <ul className={`${className}__container__list`}>
          {cardItems.map((item, index) => (
            <li
              key={`${className}__container__list__item-${index}`}
              className={`${className}__container__list__item ${className}__container__list__item--${setCardCarouselItemStatus(
                indexes,
                index
              )}`}
            >
              <CardProduct
                key={`${className}__container__list__item-card-${index}`}
                icon={item.icon}
                title={item.title}
                text={item.text}
              />
              <button
                className={`${className}__container__list__item__bar ${className}__container__list__item__bar--${setCardCarouselItemStatus(
                  indexes,
                  index
                )}`}
              ></button>
            </li>
          ))}
        </ul>
        <Control
          aria-label="Next Slide"
          icon={faAngleRight}
          className="control__next"
          onClick={handleCardTransition}
          forBackground={BackgroundType.LIGHT}
        />
      </div>
      <div className={`${className}__text-container`}>
        <p>sakdksakdaskasdlkadskdsa</p>
      </div>
    </div>
  );
};
