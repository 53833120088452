import React, { useState } from "react";
import Hamburger from "hamburger-react";
import { Brand, LabelProps } from "../../../../common";
import { toggleLayoutOverflowHidden } from "../../../../utils";
import { NAVBAR_CONFIG } from "../NavBarConfig";
import { NavLinkProps } from "../NavLink/NavLink";
import "./NavMobile.scss";

interface NavMobileProps {
  onRenderLinks: (links: Array<NavLinkProps>) => JSX.Element[] | null;
  onRenderContacts: (contacts: Array<LabelProps>) => JSX.Element[] | null;
}

const NavMobile: React.FC<NavMobileProps> = (props: NavMobileProps) => {
  const { onRenderLinks, onRenderContacts } = props;
  const { links, contacts } = NAVBAR_CONFIG;
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="nav-mobile">
        <Brand />
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          onToggle={(toggled) => toggleLayoutOverflowHidden(toggled)}
        />
      </div>
      {isOpen ? (
        <div id="nav-mobile__menu" className={`nav-mobile__menu`}>
          <nav className={`nav-mobile__menu__links`}>{onRenderLinks(links)}</nav>
          <div className={`nav-mobile__menu__contact`}>
            <div className="nav-mobile__menu__contact__links">{onRenderContacts(contacts)}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NavMobile;
