import React, { useState, useEffect } from "react";
import { NavBar } from "./NavBar";
import "./Header.scss";

export const Header = () => {
  const [isFixed, setIsFixed] = useState(false);

  const handleFixed = () => {
    if (window.scrollY >= 1) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleFixed);

    return () => {
      window.removeEventListener("scroll", handleFixed);
    };
  }, []);

  return (
    <header className={isFixed ? "header fixed" : "header"}>
      <NavBar />
    </header>
  );
};
