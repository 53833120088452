import { ReactElement } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

export enum MainMenuScreen {
  PROJECTS = "projets",
  EVENTS = "events",
  WEDDINGS = "weddings",
  COOPERATION = "cooperation",
  TRAVELS = "travels",
}

export enum AboutUsMenuScreen {
  ABOUT_US = "about-us",
  PROJECT = "project",
  FAQ = "faq",
}

export type TMenuScreen = MainMenuScreen | AboutUsMenuScreen;

export type TMenuItem = {
  icon?: IconDefinition;
  text: string;
  screenType: TMenuScreen;
  component: ReactElement<any>;
};
