import React from "react";
import { BackgroundType } from "../models";
import "./Divider.scss";

type DividerSizes = 2 | 3 | 4;

interface DividerProps {
  forBackground: BackgroundType;
  size?: DividerSizes;
}

const componentName = "divider";

const setSizeClass = (size: DividerSizes) => {
  const dividerNormalClassName = `${componentName}--normal`;

  switch (true) {
    case size === 2:
      return dividerNormalClassName;
    case size === 3:
      return `${componentName}--medium`;
    case size === 4:
      return `${componentName}--large`;
    default:
      return dividerNormalClassName;
  }
};

const Divider: React.FC<DividerProps> = (props: DividerProps) => {
  const { forBackground, size } = props;

  return (
    <hr
      className={`divider${
        forBackground == BackgroundType.LIGHT
          ? ` ${componentName}--dark`
          : ` ${componentName}--light`
      } ${setSizeClass(size)}`}
    />
  );
};

export default Divider;
