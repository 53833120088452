import React, { useState } from "react";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { FAQCategory } from "../FAQ/FAQModels";
import "./FAQItem.scss";

export interface FAQItemProps {
  category: FAQCategory;
  question: string;
  answer: string;
  isOpen: boolean;
}

export const FAQItem: React.FC<FAQItemProps> = (props: FAQItemProps) => {
  const componentName = "faq-item";
  const { question, answer, isOpen } = props;
  const [isFAQItemOpen, setIsFAQItemOpen] = useState(isOpen);

  const toggleFAQ = () => {
    setIsFAQItemOpen(!isFAQItemOpen);
  };

  return (
    <div className={`${componentName}__container`}>
      <div className={`${componentName}__container__question-container`}>
        <p className={`${componentName}__container__question-container__question`}>{question}</p>
        <KeyboardArrowRightRoundedIcon
          className={`${componentName}__container__question-container__question__icon ${componentName}__container__question-container__question__icon--${
            isFAQItemOpen ? "active" : "inactive"
          }`}
          onClick={toggleFAQ}
        />
      </div>
      <div
        className={`${componentName}__container__answer-container ${componentName}__container__answer-container--${
          isFAQItemOpen ? "active" : "inactive"
        }`}
      >
        <p className={`${componentName}__container__answer-container__answer`}>{answer}</p>
      </div>
    </div>
  );
};
