import React, { FC } from "react";
import { BackgroundType, Divider } from "../../../../../common";
import { ProjectCard, ProjectCardProps } from "../../../../Card";
import "./MainMenuCategory.scss";

export type MainMenuCategory = {
  title: string;
  items: ProjectCardProps[];
};

export interface MainMenuCategoryProps extends MainMenuCategory {
  numOfCategories?: number;
}

export const MainMenuCategory: FC<MainMenuCategoryProps> = (props: MainMenuCategoryProps) => {
  const componentName = "main-menu-category";
  const { numOfCategories, title, items } = props;

  return (
    <div className={`${componentName}`}>
      {numOfCategories && numOfCategories > 0 ? (
        <div className={`${componentName}__title-container`}>
          <h3 className={`${componentName}__title-container__title`}>{title}</h3>
          <Divider forBackground={BackgroundType.LIGHT} />
        </div>
      ) : null}
      <div className={`${componentName}__content`}>
        {items.map((item) => {
          return (
            <ProjectCard key={item.text} text={item.text} link={item.link} imgSrc={item.imgSrc} />
          );
        })}
      </div>
    </div>
  );
};
