import React, { FC } from "react";
import { useMenu } from "./hooks";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { MenuNav } from "./MenuNav/MenuNav";
import { MenuScreen } from "./MenuScreen/MenuScreen";
import { TMenuItem } from "./models";
import { MobileMenuNav } from "./MobileMenuNav/MobileMenuNav";
import { DeviceType, useScreenDimensions } from "../ScreenDimension";

export interface MenuProps {
  className?: string;
  items: TMenuItem[];
}

export const Menu: FC<MenuProps> = (props: MenuProps) => {
  const componentName = "menu";
  const { className, items } = props;
  const { currentItem, handleScreenChange } = useMenu(items);
  const screenDimensions = useScreenDimensions();

  const isDesktop =
    screenDimensions &&
    (screenDimensions.deviceType === DeviceType.LAPTOP ||
      screenDimensions.deviceType === DeviceType.LARGE ||
      screenDimensions.deviceType === DeviceType.EXTRA_LARGE);

  return (
    <div className={`${className ? `${componentName} ${className}` : componentName}`}>
      {isDesktop ? (
        <MenuNav items={items} currentItem={currentItem} onItemClick={handleScreenChange} />
      ) : (
        <MobileMenuNav items={items} currentItem={currentItem} onItemClick={handleScreenChange} />
      )}
      <MenuScreen component={currentItem.component} />
    </div>
  );
};
