import React, { FC } from "react";

import { Menu, Section } from "../../../../common";
import { MAIN_MENU_CONFIG } from "./MainMenuConfig";
import "./SectionMainMenu.scss";

export const SectionMainMenu: FC = () => {
  const componentName = "main-menu-section";
  const mainMenuItems = MAIN_MENU_CONFIG.items;

  return (
    <Section id={componentName} className={componentName}>
      <Menu items={mainMenuItems} />
    </Section>
  );
};
