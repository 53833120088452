import React from "react";

import { BackgroundType, Divider } from "../../common";
import "./FooterItem.scss";

interface FooterItemProps {
  title: string;
  className?: string;
  children: JSX.Element | Array<JSX.Element>;
}

export const FooterItem: React.FC<FooterItemProps> = (props: FooterItemProps) => {
  const { title, className, children } = props;

  return (
    <div className={`footer-item ${className ? "footer-item__" + className : ""}`}>
      <div className="footer-item__title">{title}</div>
      <Divider forBackground={BackgroundType.LIGHT} />
      <div className="footer-item__content">{children}</div>
    </div>
  );
};
