import React, { useEffect } from "react";
import { setCurrentUri } from "../../modules/AppContext/appContextSlice";
import { useAppDispatch } from "../../modules/redux";
import { Layout } from "../../containers";
import { ScrollToTop } from "../../common";
import SEO from "./SEO";
import { smoothJumpToSectionOnAnchorLink } from "../../utils";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; /* eslint-disable import/first */

interface PageProps {
  children: JSX.Element | Array<JSX.Element>;
  title: string;
  description: string;
  uri: string;
}

const Page: React.FC<PageProps> = (props: PageProps) => {
  const { children, title, description, uri } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentUri(uri));
    smoothJumpToSectionOnAnchorLink();
  }, []);

  return (
    <div className="page">
      <SEO title={title} description={description} />
      <Layout>{children}</Layout>
      <ScrollToTop />
    </div>
  );
};

export default Page;
