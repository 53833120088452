import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

import { TMenuScreen } from "../models";
import "./MenuItem.scss";
import { FAIcon } from "../../Icon";
import { ScreenType } from "../../models";

export interface MenuItemProps {
  icon?: IconDefinition;
  text: string;
  active?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  screenType: TMenuScreen;
  menuType: ScreenType;
}

export const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {
  const componentName = "menu-item";
  const { icon, text, active = false, onClick, screenType, menuType } = props;

  return (
    <button
      className={`${componentName}__${menuType}${
        active && active
          ? ` ${componentName}__${menuType}--active`
          : ` ${componentName}__${menuType}--inactive`
      }`}
      onClick={onClick}
      value={screenType}
    >
      {icon ? <FAIcon icon={icon} /> : null}
      <span>{text}</span>
    </button>
  );
};
