// based on default responsive.scss brakepoints(see src/assets/style/responsive.scss):
// xs, extra-small: 0px
// sm, small: 576px
// md, medium: 768px
// lg, large: 992px
// xl, extra-large: 1200px

export enum DeviceType {
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  LAPTOP = "laptop",
  EXTRA_LARGE = "extra-large",
}

export enum MediaQueryBrakepoint {
  EXTRA_SMALL = 0,
  SMALL = 576,
  MEDIUM = 768,
  LARGE = 992,
  LAPTOP = 1024,
  EXTRA_LARGE = 1200,
}

export type ScreenDimensions = {
  width: number;
  height: number;
  deviceType: DeviceType;
};
