import React, { FC } from "react";
import { BackgroundType, Divider, FAIcon } from "../../common";
import { FooterItem, Rights } from "../../components";
import "./Footer.scss";

const componentName = "footer";

export interface FooterProps {}

export const Footer: FC<FooterProps> = (props: FooterProps) => {
  return (
    <footer className={componentName}>
      <Divider forBackground={BackgroundType.LIGHT} />
      <div className={`${componentName}__content`}>
        <FooterItem className="about-us" title="O nama">
          <div className="about-us">
            <p>
              Na tržište dolazimo 2022. godine gdje želimo pokazati svoj pogled kroz objektiv. 
              U želji da Vaše lijepe i posebne trenutke u životu ispričamo na naš što bolji i posebniji način. 
              Tako da nam date svoje puno povjerenje i da u svojim najljepšim trenucima života budete opušteni i bez briga.
            </p>
            <p>
              Vaš Mb Creative Media
            </p>
          </div>
        </FooterItem>
        <FooterItem className="services" title="Usluge">
          <ul className="service-list">
                <li>Fotografiranje krštenja</li>
                <li>Fotografiranje djevojačkih zabava</li>
                <li>Fotografiranje rođendana</li>
                <li>Fotografiranje svatova</li>
                <li>Snimanje krštenja</li>
                <li>Snimanje svatova</li>
                <li>Snimanje reklama</li>
                <li>Izrada web stranica</li>
                <li>Godišnje i mjesečno održavanje</li>
          </ul>
        </FooterItem>
        <FooterItem className="contact" title="Kontakt">
          <div className="contact">
            <p className={`${componentName}__contact__paragraph`}>
              <FAIcon
                className={`${componentName}__contact__icon`}
                icon={["fas", "envelope"]}
                size="1x"/>
              <span className={`${componentName}__contact__text`}>
                info@mbcreativemedia.hr
              </span>
            </p>
            <p className={`${componentName}__contact__paragraph`}>
              <FAIcon
                className={`${componentName}__contact__icon`}
                icon={["fas", "address-card"]}
                size="1x"/>
              <span className={`${componentName}__contact__text`}>
                Josipa Stipana Reljkovića 3, 32281 Ivankovo, Hrvatska
              </span>
            </p>
          </div>
          {/* <Divider forBackground={BackgroundType.LIGHT} /> */}
          <div className="social-media">
            <a
              className="social-media__link"
              href="https://www.youtube.com/channel/UCdKS5FUX0eU-vVdEaNh-llg"
              target="_blank"
            >
              <FAIcon icon={["fab", "youtube"]} size="2x" />
            </a>
            <a
              className="social-media__link"
              href="https://www.instagram.com/mb.creativemedia/"
              target="_blank"
            >
              <FAIcon icon={["fab", "instagram"]} size="2x" />
            </a>
            <a
                className="social-media__link"
                href="https://web.facebook.com/MbCreativeMedia1"
                target="_blank"
              >
                <FAIcon icon={["fab", "square-facebook"]} size="2x" />
              </a>
            <a
              className="social-media__link"
              href="https://www.tiktok.com/@mbcreativemedia"
              target="_blank"
            >
              <FAIcon icon={["fab", "tiktok"]} size="2x" />
            </a>
          </div>
        </FooterItem>
      </div>
      <div className={`${componentName}__rights-section`}>
        <Divider forBackground={BackgroundType.LIGHT} />
        <Rights />
      </div>
    </footer>
  );
};
