import React from "react";

export interface MailToProps {
  email: string;
  subject: string;
  body: string;
  children: JSX.Element;
  className?: string;
}

const MailTo: React.FC<MailToProps> = ({ email, subject, body, children }) => {
  return (
    <a
      href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}&body=${
        encodeURIComponent(body) || ""
      }`}
    >
      {children}
    </a>
  );
};

export default MailTo;
