import React from "react";
import { FAQCategory, IFAQItem } from "./FAQModels";
import { FAQItem, FAQItemProps } from "../FAQItem/FAQItem";
import { setAllFAQItemsAsClosedByDefault } from "./FAQHelperFunctions";
import "./FAQ.scss";
import { BackgroundType, Divider } from "../../common";

interface FAQProps {
  items: Array<IFAQItem>;
}

export const FAQ: React.FC<FAQProps> = (props: FAQProps) => {
  const componentName = "faq";
  const { items } = props;
  const categories = Object.values(FAQCategory);

  return (
    <div className={componentName}>
      {categories.map((category, index) => {
        const categoryItems = items.filter((item) => {
          return item.category === category;
        });
        const categoryFAQItems = setAllFAQItemsAsClosedByDefault(categoryItems);

        return (
          <div key={`faq-category-${index}`} className={`${componentName}__container`}>
            <div className={`${componentName}__container__header-container`}>
              <p className={`${componentName}__container__header-container__text`}>{category}</p>
            </div>
            <Divider forBackground={BackgroundType.DARK} />
            <div className={`${componentName}__container__content`}>
              {categoryFAQItems.map((categoryFAQItem, index) => {
                return (
                  <FAQItem
                    key={`faq-item-${index}`}
                    category={categoryFAQItem.category}
                    question={categoryFAQItem.question}
                    answer={categoryFAQItem.answer}
                    isOpen={categoryFAQItem.isOpen}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
