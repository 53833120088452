import React, { useState } from "react";

import { Tooltip } from "../../common";
import { TooltipPosition } from "../Tooltip/Tooltip";
import "./CopyToClipboard.scss";

interface CopyToClipboardProps {
  textToCopy: string;
  tooltipText: string;
  tooltipPosition: TooltipPosition;
  onSuccessMessage: string;
  children: JSX.Element | Array<JSX.Element>;
}

const copyToClipboard = (textToCopy: string) =>
  navigator.clipboard.writeText(textToCopy);

const CopyToClipboard: React.FC<CopyToClipboardProps> = (
  props: CopyToClipboardProps
) => {
  const {
    tooltipText,
    tooltipPosition,
    textToCopy,
    onSuccessMessage,
    children,
  } = props;
  const [tooltipMessage, setTooltipMessage] = useState(tooltipText);

  const handleOnClick = () => {
    copyToClipboard(textToCopy);
    setTooltipMessage(onSuccessMessage);
  };

  const handleOnMouseEnter = () => {
    if (tooltipText !== tooltipMessage) {
      setTooltipMessage(tooltipText);
    }
  };

  const handleOnMouseLeave = () => {
    setTooltipMessage(tooltipText);
  };

  return (
    <button
      type="button"
      className="copy-to-clipboard"
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Tooltip position={tooltipPosition} text={tooltipMessage}>
        {children}
      </Tooltip>
    </button>
  );
};

export default CopyToClipboard;
