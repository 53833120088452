import React from "react";
import {
  BackgroundType,
  CopyToClipboard,
  DeviceType,
  Divider,
  Label,
  LabelProps,
  MailTo,
  useScreenDimensions,
} from "../../../common";
import { LabelType } from "../../../common/Label/Label";
import { TooltipPosition } from "../../../common/Tooltip/Tooltip";
import { NavLink, NavLinkProps } from "./NavLink/NavLink";
import { Nav } from "./Nav";
import { NavMobile } from "./NavMobile";
import "./NavBar.scss";

function renderLinks(links: Array<NavLinkProps>) {
  return links.length > 0
    ? links.map((link) => {
        return <NavLink key={link.key} toPath={link.toPath} icon={link.icon} text={link.text} />;
      })
    : null;
}

function renderContacts(contacts: Array<LabelProps>) {
  return contacts.length > 0
    ? contacts.map((contact) => {
        switch (contact.labelType) {
          case LabelType.MAIL_TO:
            return (
              <MailTo
                key={contact.key}
                email="info@mbcreativemedia.hr"
                subject="Upit"
                body="Poštovani,"
              >
                <Label text={contact.text} icon={contact.icon} toPath={contact.toPath} />
              </MailTo>
            );
          case LabelType.COPYABLE:
            return (
              <CopyToClipboard
                key={contact.key}
                tooltipPosition={TooltipPosition.BOTTOM}
                textToCopy={contact.text ? contact.text : ""}
                tooltipText="Kopiraj broj."
                onSuccessMessage="Broj kopiran."
              >
                <Label text={contact.text} icon={contact.icon} />
              </CopyToClipboard>
            );
          default:
            return (
              <Label
                key={contact.key}
                text={contact.text}
                icon={contact.icon}
                toPath={contact.toPath}
              />
            );
        }
      })
    : null;
}

const NavBar: React.FC = () => {
  const screenDimensions = useScreenDimensions();
  const isDesktop =
    screenDimensions &&
    (screenDimensions.deviceType === DeviceType.LAPTOP ||
      screenDimensions.deviceType === DeviceType.LARGE ||
      screenDimensions.deviceType === DeviceType.EXTRA_LARGE);

  return (
    <>
      {screenDimensions ? (
        <div className="nav-bar">
          {isDesktop ? (
            <Nav onRenderLinks={renderLinks} onRenderContacts={renderContacts} />
          ) : (
            <NavMobile onRenderLinks={renderLinks} onRenderContacts={renderContacts} />
          )}
          <Divider forBackground={BackgroundType.LIGHT} />
        </div>
      ) : null}
    </>
  );
};

export default NavBar;
