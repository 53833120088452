import baptismMobile from "../../../../assets/images/events/baptism/diego_baptism.jpg";
import baptism from "../../../../assets/images/events/baptism/diego_and_parents_baptism.jpg";
import weddingMobile from "../../../../assets/images/weddings/wedding_ana_and_lorenzo-m.jpg";
import wedding from "../../../../assets/images/weddings/wedding_ana_and_lorenzo.jpg";
import { CarouselConfig } from "../../../../common";

export const MainCarouselConfig: CarouselConfig = {
  sliedes: [
    {
      imageMobile: baptismMobile,
      image: baptism,
      title: "Krštenja",
      paragraph: `"I tko primi jedno ovakvo dijete u moje ime, mene prima."`,
      signature: "Mt. 18,5",
    },
    {
      imageMobile: weddingMobile,
      image: wedding,
      title: "Vjenčanja",
      paragraph: `"Stoga će čovjek ostaviti oca i majku da prione uza svoju ženu i bit će njih dvoje jedno tijelo"`,
      signature: "Postanak 2,24",
    },
  ],
  slideDuration: 3000,
};
