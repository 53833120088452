import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { BackgroundType, FAIcon } from "../..";
import "./Controls.scss";

export interface ControlProps {
  className?: string;
  icon?: IconDefinition;
  onClick: () => void;
  forBackground?: BackgroundType;
}

export const Control: React.FC<ControlProps> = (props: ControlProps) => {
  const { className, icon, onClick, forBackground } = props;
  return (
    <button
      className={`control ${className ? className : null} control--${
        forBackground === BackgroundType.LIGHT ? "light" : "dark"
      }`}
      type="button"
      onClick={onClick}
    >
      {icon ? <FAIcon icon={icon} className="control__icon" /> : null}
    </button>
  );
};

export interface ControlsProps {}

export const Controls: React.FC<ControlsProps> = (props) => {
  return <div className="controls" {...props} />;
};
