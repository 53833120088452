import React, { useEffect, useCallback, useState } from "react";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { CardProduct } from "../../components";
import { defaultProductCardListMobileConfig } from "./CardProductListMobileConfig";
import "./CardProductListMobile.scss";

const productCardListMobile = defaultProductCardListMobileConfig;

const setCardStatus = (indexes, cardIndex) => {
  if (indexes.currentIndex === cardIndex) {
    return "active";
  } else if (indexes.nextIndex === cardIndex) {
    return "next";
  } else if (indexes.previousIndex === cardIndex) {
    return "prev";
  }
  return "inactive";
};

const ProductCardList = ({
  style,
  onCardChange,
  containerClassName,
  cardClassName,
  leftButton,
  rightButton,
  autoRotate = false,
  rotationInterval = 2000,
  children,
}) => {
  const cardItems = productCardListMobile.items;
  const [indexes, setIndexes] = useState({
    previousIndex: cardItems.length - 1,
    currentIndex: 0,
    nextIndex: 1,
  });

  const handleCardTransition = useCallback(() => {
    // If we've reached the end, start again from the first card,
    // but carry previous value over
    if (indexes.currentIndex >= cardItems.length - 1) {
      setIndexes({
        previousIndex: cardItems.length - 1,
        currentIndex: 0,
        nextIndex: 1,
      });
    } else {
      setIndexes((prevState) => ({
        previousIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex + 1,
        nextIndex: prevState.currentIndex + 2 === cardItems.length ? 0 : prevState.currentIndex + 2,
      }));
    }
  }, [indexes.currentIndex]);

  const handleLeftButton = useCallback(() => {
    // If we've reached the end, start again from the first card,
    // but carry previous value over
    if (indexes.currentIndex <= 0) {
      setIndexes({
        previousIndex: cardItems.length - 2,
        currentIndex: cardItems.length - 1,
        nextIndex: 0,
      });
    } else {
      setIndexes((prevState) => ({
        nextIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex - 1,
        previousIndex:
          prevState.currentIndex - 1 <= 0 ? cardItems.length - 1 : prevState.currentIndex - 2,
      }));
    }
  }, [indexes.currentIndex]);

  useEffect(() => {
    onCardChange && onCardChange(indexes);
    const transitionInterval = setInterval(() => {
      autoRotate && handleCardTransition();
    }, rotationInterval);
    return () => clearInterval(transitionInterval);
  }, [handleCardTransition, indexes, autoRotate]);

  return (
    <div className="container">
      {leftButton ? (
        <span onClick={handleLeftButton}>{leftButton}</span>
      ) : (
        <span className="btn" onClick={handleLeftButton}>
          <ArrowBack style={{ fontSize: 20 }} />
        </span>
      )}
      <ul
        style={{ ...style }}
        className={`cardCarousel ${containerClassName ? containerClassName : "carouselDefault"}`}
      >
        {cardItems.map((item, index) => (
          <li
            key={`dev-process-item-${index}`}
            className={`card ${cardClassName ? cardClassName : ""} ${setCardStatus(
              indexes,
              index
            )}`}
          >
            <CardProduct
              key={`dev-process-card-${index}`}
              show={indexes.currentIndex === index}
              icon={item.icon}
              title={item.title}
              text={item.text}
            />
          </li>
        ))}
      </ul>
      {rightButton ? (
        <span onClick={handleCardTransition}>{rightButton}</span>
      ) : (
        <span className="btn" onClick={handleCardTransition}>
          <ArrowForward style={{ fontSize: 20 }} />
        </span>
      )}
    </div>
  );
};

export default ProductCardList;
