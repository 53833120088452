import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppContextState {
  currentUri: string | undefined;
}

const initialState: AppContextState = {
  currentUri: undefined,
};

const appContextSlice = createSlice({
  name: "appContext",
  initialState,
  reducers: {
    setCurrentUri(state, action: PayloadAction<string>) {
      state.currentUri = action.payload;
    },
  },
});

export const { setCurrentUri } = appContextSlice.actions;

export default appContextSlice.reducer;
