import { configureStore } from "@reduxjs/toolkit";

import { appContextReducer } from "./reducers";

export const store = configureStore({
  reducer: {
    appContext: appContextReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
