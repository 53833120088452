import React from "react";
import { Section } from "../Section/Section";
import "./SectionContactUs.scss";
import { CardContactInfo } from "../CardContactInfo/CardContactInfo";
import { Grid } from "@mui/material";

interface SectionContactUsProps {}

export const SectionContactUs: React.FC<SectionContactUsProps> = (props: SectionContactUsProps) => {
  const componentName = "section-contact-us";

  return (
    <Section title="Kontakt" id={componentName} className={componentName}>
      <Grid item xs={12} className={`${componentName}__container__content__contact`}>
          <CardContactInfo />
      </Grid>
    </Section>
  );
};
