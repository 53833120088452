import React from "react";
import { Link } from "gatsby";

import logo from "../../assets/images/logo_all-black-transparent.png";
import "./Brand.scss";

interface BrandProps {}

const Brand: React.FC<BrandProps> = () => {
  const componentName = "brand";

  return (
    <div className={componentName}>
      <Link className={`${componentName}__container`} to="/">
        <img className={`${componentName}__container__logo`} src={logo} alt="Logo" />
      </Link>
    </div>
  );
};

export default Brand;
