import React, { FC } from "react";
import { ScreenType } from "../../models";

import { MenuItem } from "../MenuItem/MenuItem";
import { TMenuItem } from "../models";

export interface MenuNavProps {
  items: TMenuItem[];
  currentItem: TMenuItem;
  onItemClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MenuNav: FC<MenuNavProps> = (props: MenuNavProps) => {
  const componentName = "menu-nav";
  const { items, currentItem, onItemClick } = props;

  return (
    <div className={`${componentName}`}>
      {items.map((item, index) => {
        return (
          <MenuItem
            key={`${componentName}__item-${index}`}
            icon={item.icon}
            text={item.text}
            active={item.screenType === currentItem.screenType}
            onClick={onItemClick}
            screenType={item.screenType}
            menuType={ScreenType.DESKTOP}
          />
        );
      })}
    </div>
  );
};
