import React, { useReducer, useEffect } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CarouselSlideConfig } from ".";
import { Control, Controls } from "./Controls";
import { ProgressBar } from "./ProgressBar";
import { Slide, SlideNav, SlideNavItem, Slides } from "./Slides";

import "./Carousel.scss";
import { DeviceType, useScreenDimensions } from "../ScreenDimension";

interface CarouselState {
  currentIndex: number;
  isPlaying: boolean;
  takeFocus: boolean;
}

const initialState = {
  currentIndex: 0,
  isPlaying: false,
  takeFocus: false,
};

interface CarouselProps {
  slides: Array<CarouselSlideConfig>;
  slideDuration: number;
  children?: JSX.Element | Array<JSX.Element>;
}

export const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const { slides, slideDuration, children } = props;
  const screenDimensions = useScreenDimensions();
  const isDesktop = screenDimensions && screenDimensions.deviceType === DeviceType.EXTRA_LARGE;

  const [state, dispatch] = useReducer((currState: CarouselState, action: any) => {
    switch (action.type) {
      case "NEXT":
      case "PROGRESS":
        return {
          ...currState,
          isPlaying: action.type === "PROGRESS",
          currentIndex: (currState.currentIndex + 1) % slides.length,
        };
      case "PAUSE":
        return {
          ...currState,
          isPlaying: false,
        };
      case "PLAY":
        return {
          ...currState,
          isPlaying: true,
        };
      case "PREV":
        return {
          ...currState,
          currentIndex: (currState.currentIndex - 1 + slides.length) % slides.length,
          isPlaying: false,
        };
      case "GOTO":
        return {
          ...currState,
          takeFocus: true,
          currentIndex: action.index,
        };
      case "UNSET_FOCUS":
        return {
          ...currState,
          takeFocus: false,
        };
      default:
        return currState;
    }
  }, initialState);

  useEffect(() => {
    if (state.isPlaying) {
      const timeout = setTimeout(() => {
        dispatch({ type: "PROGRESS" });
      }, slideDuration);
      return () => clearTimeout(timeout);
    }
  }, [state.currentIndex, state.isPlaying]);

  useEffect(() => {
    if (state.takeFocus) {
      dispatch({ type: "UNSET_FOCUS" });
    }
  }, [state.takeFocus]);

  return (
    <section className="carousel">
      <SlideNav>
        {slides.map((_slide, index) => (
          <SlideNavItem
            key={index}
            isCurrent={index === state.currentIndex}
            aria-label={`Slide ${index + 1}`}
            onClick={() => {
              dispatch({ type: "GOTO", index });
            }}
          />
        ))}
      </SlideNav>
      <Slides>
        {slides.map((slide, index) => (
          <Slide
            key={index}
            id={`image-${index}`}
            image={slide.imageMobile && !isDesktop ? slide.imageMobile : slide.image}
            title={slide.title}
            isCurrent={index === state.currentIndex}
            takeFocus={state.takeFocus}
            paragraph={slide.paragraph}
            signature={slide.signature}
          />
        ))}
      </Slides>
      <Controls>
        <Control
          aria-label="Previous Slide"
          icon={faAngleLeft}
          className="control__prev"
          onClick={() => {
            dispatch({ type: "PREV" });
          }}
        />
        {state.isPlaying ? (
          <Control
            className="control__pause"
            aria-label="Pause"
            onClick={() => {
              dispatch({ type: "PAUSE" });
            }}
          />
        ) : (
          <Control
            className="control__play"
            aria-label="Play"
            onClick={() => {
              dispatch({ type: "PLAY" });
            }}
          />
        )}
        <Control
          className="control__next"
          aria-label="Next Slide"
          icon={faAngleRight}
          onClick={() => {
            dispatch({ type: "NEXT" });
          }}
        />
      </Controls>
      {children}
      {/* <ProgressBar
        key={state.currentIndex + state.isPlaying}
        time={slideDuration}
        animate={state.isPlaying}
      /> */}

      {/* <VisuallyHidden>
        <Alert>
          Item {state.currentIndex + 1} of {slides.length}
        </Alert>
      </VisuallyHidden> */}
    </section>
  );
};

export default Carousel;
