import React from "react";
import { MainMenuCategory } from "../MainMenuCategory/MainMenuCategory";
import "./MainMenuCategories.scss";

export interface MainMenuCategoriesProps {
  categories: MainMenuCategory[];
}

export const MainMenuCategories: React.FC<MainMenuCategoriesProps> = (
  props: MainMenuCategoriesProps
) => {
  const componentName = "main-menu-categories";
  const { categories } = props;

  return (
    <div className={componentName}>
      {categories.map((category) => {
        return (
          <MainMenuCategory
            key={category.title}
            numOfCategories={categories.length}
            title={category.title}
            items={category.items}
          />
        );
      })}
    </div>
  );
};
