import React, { RefObject, useEffect, useRef } from "react";

import "./Slide.scss";

export interface SlideProps {
  id: string;
  image: string;
  title: string;
  paragraph: string;
  signature: string;
  isCurrent: boolean;
  takeFocus: boolean;
}

export const Slide: React.FC<SlideProps> = (props: SlideProps) => {
  const ref: RefObject<HTMLLIElement> = useRef<HTMLLIElement>(null);
  const { id, image, title, paragraph, signature, isCurrent, takeFocus } = props;

  useEffect(() => {
    if (isCurrent && takeFocus) {
      ref?.current?.focus();
    }
  }, [isCurrent, takeFocus]);

  return (
    <li
      ref={ref}
      aria-hidden={!isCurrent}
      tabIndex={-1}
      aria-labelledby={id}
      className="slide"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="slide__content">
        <h2 id={id} className="slide__content__title">
          {title}
        </h2>
        <p className="slide__content__paragraph">{paragraph}</p>
        <p className="slide__content__signature">{signature}</p>
      </div>
    </li>
  );
};
