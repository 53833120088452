import React, { useState } from "react";
import { TMenuItem } from "./models";

export const useMenu = (items: TMenuItem[]) => {
  const [currentItem, setCurrentItem] = useState<TMenuItem>(items[0]);

  const handleScreenChange: (event: React.MouseEvent<HTMLButtonElement>) => void = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const targetValue = event.currentTarget.value;
    if (currentItem.screenType !== targetValue) {
      const selectedItem = items.find((item) => item.screenType === targetValue);
      if (selectedItem) setCurrentItem(selectedItem);
    }
  };

  return { currentItem, handleScreenChange };
};
