import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faCopyright, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { fas, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab, fas, faCopyright, faEnvelope, faLightbulb);

/** @param {FontAwesomeIconProps} props */
/* eslint-disable react/jsx-props-no-spreading */
const FAIcon: React.FC<FontAwesomeIconProps> = ({ ...props }: FontAwesomeIconProps) => (
  <FontAwesomeIcon {...props} />
);

export { FAIcon };
