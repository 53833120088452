import React from "react";
import { BackgroundType, Divider } from "../../common";
import "./Section.scss";

interface SectionProps {
  id?: string;
  className?: string;
  children: JSX.Element | Array<JSX.Element>;
  title?: string;
  subtitle?: JSX.Element | Array<JSX.Element>;
  footer?: JSX.Element | Array<JSX.Element>;
}

export const Section: React.FC<SectionProps> = (props: SectionProps) => {
  const componentName = "page-section";
  const { id, className, children, title, subtitle, footer } = props;

  return (
    <section
      id={`${id ? id : null}`}
      className={`${className ? `${componentName} ${className}` : componentName}`}
    >
      {title || subtitle ? (
        <div className={`${componentName}__header`}>
          {title ? (
            <div className={`${componentName}__header__title`}>
              <h1 className={`${componentName}__header__title__text`}>{title}</h1>
              <Divider forBackground={BackgroundType.DARK} />
            </div>
          ) : null}
          {subtitle ? <div className={`${componentName}__header__subtitle`}>{subtitle}</div> : null}
        </div>
      ) : null}
      <div className={`${componentName}__content`}>{children}</div>
      {footer ? <div className={`${componentName}__footer`}>{footer}</div> : null}
    </section>
  );
};
