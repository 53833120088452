import React from "react";
import { ContactForm, FAIcon } from "../../common";
import "./CardContactInfo.scss";

interface CardContactInfoProps {}

export const CardContactInfo: React.FC<CardContactInfoProps> = (props: CardContactInfoProps) => {
  const componentName = "contact-info";
  return (
    <div className={componentName}>
      <div className={`${componentName}__container`}>
        {/* <div className={`${componentName}__container__partnership`}>
          <p className={`${componentName}__container__partnership__title`}>Započnimo suradnju!</p>
          <p className={`${componentName}__container__partnership__text`}>
            <span>
              Radujemo se pitanjima, projektima i novim suradnjama. Na raspolaganju smo Vam u bilo
              kojem vremenu.
            </span>
            <br></br>
            <span>Kako bi nas kontaktirali ispunite dolje navedeni obrazac.</span>
          </p>
          <ContactForm />
          <br></br>
        </div>*/}
        <div className={`${componentName}__container__other`}>
          <div className={`${componentName}__container__other__contact-details`}>
            <p>
              <i>Za sve ostale upite kontaktirajte nas na:</i>
            </p>
            <ul className={`${componentName}__container__other__contact-details__list`}>
              <li className={`${componentName}__container__other__contact-details__list__item`}>
                <FAIcon
                  className={`${componentName}__container__other__contact-details__list__item__icon`}
                  icon={["fas", "phone"]}
                  size="1x"
                />
                <span
                  className={`${componentName}__container__other__contact-details__list__item__text`}
                >
                  +385996784753
                </span>
              </li>
              <li className={`${componentName}__container__other__contact-details__list__item`}>
                <FAIcon
                  className={`${componentName}__container__other__contact-details__list__item__icon`}
                  icon={["fas", "envelope"]}
                  size="1x"
                />
                <span
                  className={`${componentName}__container__other__contact-details__list__item__text`}
                >
                  info@mbcreativemedia.com
                </span>
              </li>
            </ul>
          </div>
          <div className={`${componentName}__container__other__social-media`}>
            <p className={`${componentName}__container__other__social-media__text`}>
              <b>Pratite nas na:</b>
            </p>
            <div className={`${componentName}__container__other__social-media__links`}>
              <a
                className={`${componentName}__container__other__social-media__links__link`}
                href="https://www.youtube.com/channel/UCdKS5FUX0eU-vVdEaNh-llg"
                target="_blank"
              >
                <FAIcon icon={["fab", "youtube"]} size="2x" />
              </a>
              <a
                className={`${componentName}__container__other__social-media__links__link`}
                href="https://www.instagram.com/mb.creativemedia/"
                target="_blank"
              >
                <FAIcon icon={["fab", "instagram"]} size="2x" />
              </a>
              <a
                className={`${componentName}__container__other__social-media__links__link`}
                href="https://web.facebook.com/MbCreativeMedia1"
                target="_blank"
              >
                <FAIcon icon={["fab", "square-facebook"]} size="2x" />
              </a>
              <a
                className={`${componentName}__container__other__social-media__links__link`}
                href="https://www.tiktok.com/@mbcreativemedia"
                target="_blank"
              >
                <FAIcon icon={["fab", "tiktok"]} size="2x" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
