import React from "react";
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; /* eslint-disable import/first */
import { ThemeProvider, createTheme } from "@mui/material";
import { Footer, Header } from "../../components";
import "./Layout.scss";

interface LayoutProps {
  children: JSX.Element | Array<JSX.Element>;
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { children } = props;
  const darkTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  return (
    <div className="layout">
      <ThemeProvider theme={darkTheme}>
        <Header />
        <main className="main">{children}</main>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default Layout;
