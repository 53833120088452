import { IFAQItem } from "./FAQModels";
import { FAQItemProps } from "../FAQItem/FAQItem";

export function setAllFAQItemsAsClosedByDefault(items: Array<IFAQItem>) {
  const FAQItems: FAQItemProps[] = items.map((items) => ({
    ...items,
    isOpen: false,
  }));

  return FAQItems;
}
