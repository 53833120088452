import React, { FC } from "react";
import { Link } from "gatsby";
import "./ProjectCard.scss";

export interface ProjectCardProps {
  text: string;
  link: string;
  imgSrc: string;
}

export const ProjectCard: FC<ProjectCardProps> = (props: ProjectCardProps) => {
  const componentName = "project-card";
  const { text, link, imgSrc } = props;

  return (
    <Link className={componentName} to={link} style={ { backgroundImage: `url("${imgSrc}")`}}>
      <div className={`${componentName}__text-container`}>
        <span className={`${componentName}__text-container__text`}>{text}</span>
      </div>
    </Link>
  );
};
