import React from "react";
import "./SectionScreenAboutUs.scss";

const componentName = "section-screen-about-us";

export interface SectionScreenAboutUsProps {
  className?: string;
}

export const SectionScreenAboutUs: React.FC<SectionScreenAboutUsProps> = (
  props: SectionScreenAboutUsProps
) => {
  const { className } = props;

  return (
    <div className={`${className ? `${componentName} ${className}` : componentName}`}>
      <p>Sada par riječi o meni. Ja sam Matej, rođen u Vinkovcima (Hrvatska).</p>
      <p>
        Još kao dijete bio sam okrenut više kreativnim stvarima i željom za stvaranje. U početku su
        to bile Lego kocke s kojima se nisam znao prestati igrati.{" "}
      </p>
      <p>
        Kroz svoje odrastanje tu kreativnost sam pretakao u ono s čim sam se bavio a tada najveća
        ljubav rukomet.
      </p>
      <p>
        Kako sam odrastao otvarali su se novi putevi, nove sredine školovanja, novi ljudi, nova
        prijateljstva. I tako sam došao u Osijek gdje sam upisao fakultet elektrotehnike,računarstva
        i informacijskih tehnologija. U tim vremenima moja kreativnost se polako počela gasiti jer
        sva ta teorija i suha učenja su me odvela daleko od moje dječje kreativnosti.{" "}
      </p>
      <p>
        Kupnjom svoje prve GoPro kamere još 2017. godine, kreativnost se u meni ponovo budi i vraća
        u vrijeme kad sam bio dijete. Počinjem izlaziti iz svoje komfort zone, počinjem putovati,
        stvarati fotke i video zapise koje definitivno ostavljaju životne uspomene. Ubrzo nakon toga
        zapošljavam se preko studentskog servisa u Red Bullu. Gdje je konačno moja kreativnost došla
        do izražaja i urodila plodom.
      </p>
      <p>
        2021. godine kupujem svoju prvu profi kameru i bacam se u ozbiljan rad iza objektiva.
        Napokon se čovjek nađe na svom putu i u ono što definitivno želi raditi. Ubrzo nakon toga
        otvaram svoju firmu. I sada su nekad snovi postali stvarnost. Da radim ono što volim i da
        svoju kreativnost i viziju mogu slobodno izraziti kroz nove projekte i s novim klijentima u
        različitom području rada.{" "}
      </p>
      <p>
        Zato za sada svoju diplomu ostavljam okačenu na zidu, a s Vama želim graditi nešto što će
        nam trajno ostati u sjećanju svima nama.
      </p>
    </div>
  );
};
